import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// components
import NavBar from "pages/layout/NavBar";
import Wizard from "./Wizard";
import List from "./List";
import Hero from "./Hero";
import Services from "./Services";
import Layouts from "./Layouts";
import Features from "./Features";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import Testimonial from "./Testimonial";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Clients from "./Clients";

// dummy data
import {
  services,
  layouts,
  features,
  // plans,
  rawFaqs,
  testimonial,
} from "./data";

import {
  useGetExpoQuery,
} from "services/api";

const ExpoRegistration = () => {
  let { slug } = useParams();
  
  const { expo, errorExpo, isUninitialized: isUninitializedExpo, isLoading: isLoadingExpo, isFetching: isFetchingExpo, isError: isErrorExpo } = useGetExpoQuery(
    {
      slug: slug
    },
    {
      selectFromResult: ({ data, error, isUninitialized, isLoading, isFetching, isError }) => ({
        expo: data?.result
          ? data.result.find((el: any) => el.slug == slug)
          : null,
        errorExpo: error,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      // refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  /**
   * reach to top of web page
   */
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (document.body)
      document.body.classList.remove(
        "authentication-bg",
        "authentication-bg-pattern"
      );

    // manage go to top button
    let mybutton = document.getElementById("back-to-top-btn");
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        mybutton!.style.display = "block";
      } else {
        mybutton!.style.display = "none";
      }
    });
  }, []);

  // useEffect(() => {
  //   console.log(slug)
  // }, [slug])

  return (
    <div id="landing">
      {/* navbar */}
      <NavBar  />

      {/* hero */}
      <Wizard
        slug={slug}
        expo={expo}
      />

      {/* hero */}
      {/* <Hero /> */}

      {/* Clients */}
      {/* <Clients /> */}

      {/* services */}
      {/* <Services services={services} /> */}

      {/* layout demos */}
      {/* <Layouts layouts={layouts} /> */}

      {/* features */}
      {/* <Features features={features} /> */}

      {/* pricing */}
      {/* <Pricing /> */}

      {/* faqs */}
      {/* <FAQ rawFaqs={rawFaqs} /> */}

      {/* testimonial */}
      {/* <Testimonial testimonial={testimonial} /> */}

      {/* contact */}
      {/* <ContactUs /> */}

      {/* footer */}
      {/* <Footer /> */}

      <Link
        to="#"
        onClick={() => topFunction()}
        className="back-to-top-btn btn btn-primary"
        id="back-to-top-btn"
      >
        <i className="mdi mdi-chevron-up"></i>
      </Link>
      
      <ToastContainer
        hideProgressBar
        pauseOnHover
      />
    </div>
  );
};


export default ExpoRegistration;
