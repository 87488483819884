import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-light.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
    

      <footer className="footer footer-alt">
        {new Date().getFullYear()} &copy; JACK Study Abroad. All rights reserved.{" "}
      </footer>
    </>
  );
};

export default Footer;
