import React, { useState, useEffect, InputHTMLAttributes } from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";

import { FieldErrors, Control } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface PasswordInputProps {
  name: string;
  placeholder?: string;
  refCallback?: any;
  errors: FieldErrors;
  control?: Control<any>;
  register?: any;
  className?: string;
}

/* Password Input */
const PasswordInput = ({
  name,
  placeholder,
  refCallback,
  errors,
  control,
  register,
  className,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <InputGroup className="mb-0">
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          name={name}
          id={name}
          as="input"
          ref={(r: HTMLInputElement) => {
            if (refCallback) refCallback(r);
          }}
          className={className}
          isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name) : {})}
          autoComplete={name}
        />
        <div
          className={classNames("input-group-text", "input-group-password", {
            "show-password": showPassword,
          })}
          data-password={showPassword ? "true" : "false"}
        >
          <span
            className="password-eye"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          ></span>
        </div>
      </InputGroup>
    </>
  );
};

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: FieldErrors;
  control?: Control<any>;
  setValue?: any;
  trigger?: any;
  className?: string;
  labelClassName?: string;
  containerClass?: string;
  refCallback?: any;
  children?: any;
  rows?: string;
  value?: any;
  defaultValue?: any;
  isAllowed?: any;
  onChildChange?: any;
}

const FormInput = ({
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  control,
  setValue,
  trigger,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  rows,
  value,
  defaultValue,
  isAllowed,
  onChildChange,
  ...otherProps
}: FormInputProps) => {
  // handle input type
  const comp =
    type === "textarea" ? "textarea" : type === "select" ? "select" : "input";

  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(defaultValue);
  const [file, setFile] = useState<any>();

  const generateFile = async () => {
    if(defaultValue){
      if(typeof defaultValue == "string"){
        let blob = await fetch(defaultValue).then(r => r.blob());
        let newFile = new File([blob], "filename")
        setFile(newFile)
        setValue('fi_invoice_attachment', newFile)
        console.log('newFile1')
      } else {
        setFile(defaultValue)
        setValue('fi_invoice_attachment', defaultValue)
        console.log('newFile2')
      }
    } else {
      setValue('fi_invoice_attachment', undefined)
      console.log('newFile3')
    }
  }

  useEffect(() => {
    let loading = true

    setLoading(true)
    if(type == 'file'){
      generateFile()
    }
    setLoading(false)

    return () => {
      loading = false
    }
  }, [defaultValue])
  
  if(loading){
    return <></>
  }

  return (
    <>
      {type === "hidden" ? (
        <input
          type={type}
          name={name}
          {...(register ? register(name) : {})}
          {...otherProps}
        />
      ) : (
        <>
          {type === "password" ? (
            <>
              <Form.Group className={containerClass}>
                {label ? (
                  <>
                    {" "}
                    <Form.Label className={labelClassName}>
                      {label}
                    </Form.Label>{" "}
                    {children}{" "}
                  </>
                ) : null}
                <PasswordInput
                  name={name}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors!}
                  register={register}
                  className={className}
                />

                {errors && errors[name] ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors[name]["message"]}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </>
          ) : (
            <>
              {type === "checkbox" || type === "radio" || type === "switch" ? (
                <>
                  <Form.Group className={containerClass}>
                    <Form.Check
                      type={type}
                      label={label}
                      name={name}
                      id={name}
                      ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                      }}
                      className={className}
                      isInvalid={errors && errors[name] ? true : false}
                      {...(register ? register(name) : {})}
                      {...otherProps}
                      checked={checked}
                      onChange={(e: any) => {
                          setChecked(e.target.checked)
                          if(setValue !== undefined){
                            setValue(name, e.target.checked, true)
                          }
                          if(onChildChange){
                            onChildChange(e)
                          }
                          if(trigger !== undefined){
                            trigger(name)
                          }
                        }
                      }
                    />

                    {errors && errors[name] ? (
                      <Form.Control.Feedback type="invalid">
                        {errors[name]["message"]}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </>
              ) : (
                <Form.Group className={containerClass}>
                  {label ? (
                    <Form.Label className={labelClassName}>{label}</Form.Label>
                  ) : null}
                  {
                    (type === "text" || type === "textarea") && 
                    <Form.Control
                      type={type}
                      placeholder={placeholder}
                      name={name}
                      id={name}
                      as={comp}
                      ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                      }}
                      className={className}
                      isInvalid={errors && errors[name] ? true : false}
                      {...(register ? register(name) : {})}
                      rows={rows}
                      {...otherProps}
                      autoComplete={name}
                      defaultValue={defaultValue || undefined}
                    >
                      {children ? children : null}
                    </Form.Control>
                  }
                  {type === "number" && (
                    <NumericFormat
                      className={`form-control ${className || ''} ${
                        errors && errors[name] ? "is-invalid" : ""
                      }`}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      defaultValue={defaultValue || null}
                      value={defaultValue || null}
                      onValueChange={(e: any) => {
                          if(setValue !== undefined){
                            setValue(name, e.floatValue, true)
                          }
                        }
                      }
                      // isAllowed={(values) => {
                      //   const { floatValue } = values;
                      //   if (floatValue == 0) {
                      //     return true;
                      //   }
                      //   if (floatValue) {
                      //     return floatValue > 0 && floatValue < 1000000000;
                      //   }
                      //   return false;
                      // }}
                      isAllowed={isAllowed}
                      type="text"
                      placeholder={placeholder}
                      name={name}
                      id={name}
                      as={comp}
                      ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                      }}
                      isInvalid={errors && errors[name] ? true : false}
                      {...(register ? register(name) : {})}
                      rows={rows}
                      {...otherProps}
                      autoComplete={name}
                    />
                  )}
                  {
                    type === "file" && 
                    <Form.Control
                      type={type}
                      placeholder={placeholder}
                      name={name}
                      id={name}
                      as={comp}
                      ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                      }}
                      className={className}
                      isInvalid={errors && errors[name] ? true : false}
                      {...(register ? register(name) : {})}
                      rows={rows}
                      {...otherProps}
                      autoComplete={name}
                      defaultValue={file || undefined}
                    >
                      {children ? children : null}
                    </Form.Control>
                  }

                  {errors && errors[name] ? (
                    <Form.Control.Feedback type="invalid">
                      {errors[name]["message"]}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormInput;