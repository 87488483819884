import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import NoDataIcon from 'assets/lottiefiles/no-data.json'

import {
  useGetExpoQuery,
} from "services/api";

const CardItem = ({data, onClickEdit} : { data: any, onClickEdit?: any}) => {
  const navigate = useNavigate();

  const onClickTitle = (e: any, data: any) => {
    e.preventDefault();
    e.stopPropagation();

    if(data.status == 'Open'){
      navigate(`/${data.slug}`)
    }
  }

  return (
    <Card>
      <img
        src={data.image}
        // src={'https://placehold.co/600x400'}
        className="card-img"
        alt=""
        style={{minHeight: '190px'}}
      />
      <Card.Body>
        <a onClick={(e) => { onClickTitle(e, data) }} href="">
            <h4 className="my-2 fw-bold">
              {data.name}
              <Badge bg={data?.status == 'Open' ? 'success' : 'secondary'} className="ms-1 font-12">
                {data?.status}
              </Badge>
            </h4>
        </a>
        <div className="mt-2">
          <div className="d-flex justify-content-start align-items-center">
            <strong><i className="mdi mdi-barcode-scan me-1 font-18"/></strong>
            <span className="">#{data.code}</span>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <strong><i className="mdi mdi-timer-outline me-1 font-18"/></strong>
            <span className="">{data.start_time} - {data.end_time}</span>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <strong><i className="mdi mdi-calendar me-1 font-18"/></strong>
            <span className="">{data.date}</span>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <strong><i className="mdi mdi-google-maps me-1 font-18"/></strong>
            <span className="">{data.venue}</span>
          </div>
        </div>
        {/* <div className="d-flex flex-column gap-1 align-items-center mb-1">
          <Button
            size="sm"
            onClick={(e) => navigate(`/jsb/events/${data.code}`)}
            variant="link"
          >
            <i className="mdi mdi-link-variant me-1"/>Open registration link
          </Button>
          <Button
            size="sm"
            onClick={(e) => onClickEdit(data)}
            variant="info"
          >
            <i className="mdi mdi-pencil me-1"/>Edit Event
          </Button>
        </div> */}
      </Card.Body>
    </Card>
  );
};

const List = () => {
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const {
    data: lists,
    isFetching,
    isLoading,
    isUninitialized,
    error,
    isError,
    refetch,
  } = useGetExpoQuery(
    {},
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      refetchOnReconnect: true,
      // skip: false,
    }
  );

  useEffect(() => {
    console.log('error', error)
  }, [isError])

  return (
    <section className="section-sm bg-home bg-light" id="features">
      <div className="container-fluid">
        <div className="row">
          {
            lists?.result?.length == 0 ? (
              <div className="position-fixed top-50 start-50 translate-middle text-center">
                <Player
                  autoplay
                  keepLastFrame
                  src={NoDataIcon}
                  style={{ height: '150px', width: '150px' }}
                /> 
                <p>No data</p>
              </div>
            ) : (
              <>
                {
                  (lists?.result || []).map((expo: any) => (
                    <div className="col-12 col-sm-4 col-xl-3">
                      <CardItem data={expo} />
                    </div>
                  ))
                }
              </>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default List;
