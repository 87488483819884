import React, { useState } from "react";
import { Row, Col, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

import {
  VerticalForm,
  FormInput,
  FormDate,
  FormSelect,
  FormSelectAsync,
} from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { Player } from '@lottiefiles/react-lottie-player';
import SuccessIcon from 'assets/lottiefiles/success.json'

import {
  useCheckLeadsMutation,
  useSearchLeadsMutation,
  useGetOptionQuery,
} from "services/api";

const validation = yupResolver(
  yup.object().shape({
    full_name: yup.string().required(''),
    dob: yup.string().required(),
  })
);

const FormInterested = ({
  slug,
  expo,
  layout,
  setLayout,
  setLeads,
  leads,
}: {
  slug?: string;
  expo?: any;
  layout: string;
  setLayout: any;
  setLeads: any;
  leads: any;
}) => {
  const [leadsCode, setLeadsCode] = useState('JSA23298485');
  const [fullName, setFullName] = useState('');
  const [birth, setBirth] = useState('');
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const [searchLeads, { isLoading: isLoadingSearch }] = useSearchLeadsMutation();

  const onClickReset = () => {
    setLeads({})
    setLayout('check')
  }

  return (
    <div>
      <div className="text-center mt-4">
        <h3 className="fw-normal">Thank you <span className="fw-bold">{leads.full_name.split(' ')[0] || ''},</span></h3>
        <h4>Your registration has been confirmed</h4>
        <div className="my-4">
          <QRCode value={leads.leads_code} size={300}/>
          {/* <Player
            autoplay
            keepLastFrame
            src={SuccessIcon}
            style={{ height: '200px', width: '200px' }}
          /> */}
          {/* <h3>#{expo.code}</h3> */}
        <h2 className="text-blue mt-2 d-flex justify-content-center align-items-center">
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip
                style={{ position: "fixed" }}
                id={`tooltip-${"top"}`}
              >
                Copy
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={expo.leads_code} 
              onCopy={() => {
                toast.success("Copied", {
                  autoClose: 100,
                  hideProgressBar: true,
                });
              }}
            >
              <i className="mdi mdi-content-copy font-22 pe-1 cursor-pointer"></i>
            </CopyToClipboard>
          </OverlayTrigger>
          <span>{leads.leads_code}</span>
        </h2>
        <h5 className="fw-normal">This is your JACK Study Abroad ID.</h5>
        </div>
        <h5 className="fw-normal px-auto px-sm-5">Please hold on to this ID and present it to our staff on the day of the event.</h5>
        <h5 className="fw-normal">We look forward to seeing you at the event.</h5>
      </div>
      <hr/>
      <div className="text-start d-flex justify-content-center gap-2">
        <Button
          variant="link"
          type="button"
          className="waves-effect waves-light text-decoration-underline"
          onClick={() => onClickReset()}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <MdOutlineNavigateBefore size={22} /> Finish
          </div>
        </Button>
      </div>
    </div>
  );
};

export default FormInterested;
