import React, { useEffect } from "react";
import { Nav, Navbar, Container
  // , Button 
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// store
// import { RootState } from "redux/store";

import logo from "../../assets/images/logo-light.png";
import logo1 from "../../assets/images/logo-dark.png";

const NavBar = () => {
  const location = useLocation();

  return (
    <>
      <Navbar
        collapseOnSelect
        expand={false}
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark nav-sticky"
        id="sticky"
        style={{boxShadow:'unset'}}
      >
        <Container className="container-fluid">
          <Link to="/" className="logo text-uppercase">
            <img src={logo} alt="" className="logo-light" height="50" />
            <img src={logo1} alt="" className="logo-dark" height="50" />
          </Link>

          {/* <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="responsive-navbar-nav"
          >
            <i className="mdi mdi-menu"></i>
          </Navbar.Toggle> */}

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav as="ul" className="mx-auto navbar-center">
            </Nav>
            {/* {
              location.pathname != '/' &&
              <Link to="/" className="btn btn-info navbar-btn">
                All Event
              </Link>
            } */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
