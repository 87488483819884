import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import {
  VerticalForm,
  FormInput,
  FormDate,
  FormSelect,
  FormSelectAsync,
} from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { faker } from '@faker-js/faker';

import {
  useCheckLeadsMutation,
  useCreateLeadsMutation,
  useSearchLeadsMutation,
  useGetOptionQuery,
  useSearchOptionMutation,
} from "services/api";
import { useEffectOnce } from "usehooks-ts";

const validation = yupResolver(
  yup.object().shape({
    // leads_id
    // title: yup.string().required('').label('Title'),
    full_name: yup.string().required('').label('Full name'),
    email: yup.string().email().required('').label('Email'),
    phone: yup.string().min(10,'Please fill a valid phone number').max(20).required('').label('Phone'),
    // dob: yup.string().required('').label('Date of birth'),
    address: yup.string().required('').label('Address'),
    // zip_code: yup.string().required('').label('ZIP Code'),
    kecamatan_code: yup.string().required('').label('Subdistrict'),
    kota_name: yup.string().label('City'),
    provinsi_name: yup.string().label('Province'),
    // kota_id: yup.string().required('').label('City'),
    // provinsi_id: yup.string().required('').label('Province'),
    parents_name: yup.string().nullable().label('Parents name'),
    parents_phone: yup.string().max(20).nullable().label('Parents phone'),
    parents_email: yup.string().email().nullable().label('Parents email'),
  })
);

const FormPersonal = ({
  slug,
  expo,
  layout,
  setLayout,
  setLeads,
  leads,
  isNewLeads,
  setIsNewLeads,
}: {
  slug?: string;
  expo?: any;
  layout: string;
  setLayout: any;
  setLeads: any;
  leads: any;
  isNewLeads: boolean;
  setIsNewLeads: any;
}) => {
  const [leadsCode, setLeadsCode] = useState('JSA23298485');
  const [fullName, setFullName] = useState('');
  const [birth, setBirth] = useState('');
  
  const [kota, setKota] = useState('');
  const [provinsi, setProvinsi] = useState('');

  const [searchLeads, { isLoading: isLoadingSearch }] = useSearchLeadsMutation();

  const [checkLeads, { isUninitialized: isUninitializedCheck, isLoading: isLoadingCheck, isSuccess: isSuccessCheck, isError: isErrorCheck}] = useCheckLeadsMutation();
  const [createLeads, { isUninitialized: isUninitializedCreate, isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate}] = useCreateLeadsMutation();

  const [searchOption, { isLoading: isLoadingSearchOption }] = useSearchOptionMutation();
  const loadKecamatanOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchOption({
        type: 'KCM',
        search: inputValue,
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };
  const loadKotaOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchOption({
        type: 'KTA',
        search: inputValue,
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };
  const loadProvinsiOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchOption({
        type: 'PRV',
        search: inputValue,
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const { data: kecamatanList } = useGetOptionQuery({type: 'KCM'});
  const { data: kotaList } = useGetOptionQuery({type: 'KTA'});
  const { data: provinsiList } = useGetOptionQuery({type: 'PRV'});

  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (values: any) => {
    await createLeads({
      ...values,
      // dob: moment(values.dob,'DD-MM-YYYY').format('YYYY-MM-DD'),
      slug,
    })
      .unwrap()
      .then((res: any) => {
        if(res.result){
          setLeads(res.result)
          setLayout('interested')
        } else {
          setLeads({})
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
  };

  // const onClickBack = () => {
  //   setLeads({})
  //   setLayout('check')
  // }
  
  const onClickCheckId = () => {
    setIsNewLeads(false);
    setLeads({})
    setLayout('check')
  }

  // useEffectOnce(() => {
  //   setValue('full_name',faker.person.fullName());
  //   setValue('title','Mr');
  //   setValue('email',faker.internet.email());
  //   setValue('phone','+6298127398123');
  //   setValue('dob','11-02-2000');
  //   setValue('address','Jl. Pengairan No. 12');
  //   setValue('zip_code','57467');
  //   setValue('kecamatan_code','KEC0003181');
  //   setValue('kota_name','Kota Semarang');
  //   setValue('provinsi_name','Jawa Tengah');
  //   // setValue('kota_id','kota_id');
  //   // setValue('provinsi_id','provinsi_id');
  //   setValue('parents_name',faker.person.fullName());
  //   setValue('parents_phone','+6298127398123');
  //   setValue('parents_email',faker.internet.email());
  // })

  useEffect(() => {
    console.log(errors)
  }, [errors])

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) => onSubmit(values))}
        noValidate
      >
        <p className="text-center text-muted mb-0">Already have a JACK Study Abroad ID ? <a onClick={() => {onClickCheckId()}} className="text-decoration-underline text-decoration-underline cursor-pointer">Click here</a></p>
        <hr/>
        <h4 className="">Personal Details</h4>
        {/* <p className="sub-header">
          Most common form control, text-based input fields. Includes support
          for all HTML5 types: 
        </p> */}
        {/* <FormInput
          className="bg-light"
          label="JACK Study Abroad ID"
          type="text"
          name="leads_id"
          placeholder=""
          containerClass={"mb-3 w-50"}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          defaultValue={leads?.leads_id || undefined}
          disabled
        /> */}
        <div className="row">
          {/* <div className="col-4 col-lg-3">
            <FormSelect
              label="Title"
              type="text"
              name="title"
              placeholder="e.g Mr"
              containerClass={"mb-3"}
              defaultValue={undefined}
              options={[
                { value: "Mr", label: "Mr" },
                { value: "Mrs", label: "Mrs" },
                { value: "Miss", label: "Miss" },
              ]}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(opt: any) => {
                setValue('title', opt.value);
              }}
            />
          </div> */}
          <div className="col-12 col-sm-12 order-1 order-sm-1">
            <FormInput
              label="Full Name"
              type="text"
              name="full_name"
              placeholder="e.g. Agus Hanif"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.full_name || undefined}
            />
          </div>
        {/* </div> */}
        {/* <div className="row"> */}
          <div className="col-12 col-sm-6 order-2 order-sm-3">
            <FormInput
              label="Email"
              type="text"
              name="email"
              placeholder="e.g. agushan392@gmail.com"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.email || undefined}
            />
          </div>
        {/* </div> */}
        {/* <div className="row"> */}
          <div className="col-12 col-sm-6 order-3 order-sm-4">
            <FormInput
              label="Phone"
              type="text"
              name="phone"
              placeholder="e.g. +628134567890"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.phone || undefined}
            />
          </div>
        {/* </div> */}
        {/* <div className="row"> */}
          {/* <div className="col-12 col-sm-6 order-4 order-sm-2">
            <FormDate
              label="Date of Birth"
              type="date"
              name="dob"
              placeholder="e.g 10 Jan 2000"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              defaultValue={undefined}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-12 order-5">
            <FormInput
              label="Address & Postcode"
              type="textarea"
              name="address"
              placeholder="e.g. Jl. Pengairan No. 12, Kel. Kebon Kacang 10240"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.address || undefined}
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col-4">
            <FormInput
              label="Postcode"
              type="text"
              name="zip_code"
              placeholder="e.g. 10270"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.zip_code || undefined}
              minLength={5}
              maxLength={5}
            />
          </div> */}
          <div className="col-12 order-6">
            <FormSelectAsync
              className="w-100"
              label="Subdistrict (Kecamatan), City & Province"
              name="kecamatan_code"
              placeholder="e.g. Tanah Abang, Jakarta Pusat..."
              containerClass={"mb-3"}
              // defaultValue={data?.kecamatan_code ? {
              //   value: data?.kecamatan_code,
              //   label: data?.kecamatan_name
              // } : undefined}
              // defaultValue={{
              //   value: 'KEC0003181',
              //   label: 'Tembalang'
              // }}
              // defaultOptions={[{
              //   value: 'KEC0003181',
              //   label: 'Tembalang'
              // }]}
              loadOptions={loadKecamatanOptions}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              // setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              onChange={(option: any) => {
                setValue('kecamatan_code', undefined)
                setValue('kota_name', undefined)
                setValue('provinsi_name', undefined)
                if(option){
                  setValue('kecamatan_code', option.value)
                  let arr = option?.label?.split(',');
                  if(arr){
                    setValue('kota_name',arr[1].trim())
                    setValue('provinsi_name',arr[2].trim())
                  }
                }
              }}
              isClearable
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6">
            <FormInput
              className="bg-light"
              label="City"
              type="text"
              name="kota_name"
              placeholder=""
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.kota_name || undefined}
              disabled
            />
          </div>
          <div className="col-6">
            <FormInput
              className="bg-light"
              label="Province"
              type="text"
              name="provinsi_name"
              placeholder=""
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.provinsi_name || undefined}
              disabled
            />
          </div>
        </div> */}
        {/* <FormSelectAsync
          className="w-100"
          label="City"
          name="kota_id"
          placeholder=""
          containerClass={"mb-3"}
          // defaultValue={data?.kota_id ? {
          //   value: data?.kota_id,
          //   label: data?.kota_name
          // } : undefined}
          // defaultOptions={data?.kota_id ? [{
          //   value: data?.kota_id,
          //   label: data?.kota_name
          // }] : undefined}
          loadOptions={loadKotaOptions}
          // disabled={isLoading}
          register={register}
          control={control}
          errors={errors}
          // setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          onChange={(option: any) => {
            setValue('kota_id', option.value)
          }}
        />
        <FormSelectAsync
          className="w-100"
          label="Province"
          name="provinsi_id"
          placeholder=""
          containerClass={"mb-3"}
          // defaultValue={data?.provinsi_id ? {
          //   value: data?.provinsi_id,
          //   label: data?.provinsi_name
          // } : undefined}
          // defaultOptions={data?.provinsi_id ? [{
          //   value: data?.provinsi_id,
          //   label: data?.provinsi_name
          // }] : undefined}
          loadOptions={loadProvinsiOptions}
          // disabled={isLoading}
          register={register}
          control={control}
          errors={errors}
          // setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          onChange={(option: any) => {
            setValue('provinsi_id', option.value)
          }}
        /> */}
        {/* <h4 className="">Emergency Contact (Optional)</h4>
        <div className="row">
          <div className="col-12">
            <FormInput
              label="Name"
              type="text"
              name="parents_name"
              placeholder="e.g. Dwi Kuncoro"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.parents_name || undefined}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormInput
              label="Phone"
              type="text"
              name="parents_phone"
              placeholder="e.g. +628134567890"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.parents_phone || undefined}
            />
          </div>
          <div className="col-12">
            <FormInput
              label="Email"
              type="text"
              name="parents_email"
              placeholder="e.g. dwikcr@gmail.com"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.parents_email || undefined}
            />
          </div>
        </div> */}
        <hr/>
        <div className="text-start d-flex justify-content-end gap-2">
          {/* <Button
            size="lg"
            variant="light"
            type="button"
            className="waves-effect waves-light"
            onClick={() => {onClickBack()}}
            style={{width: '120px'}}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <MdOutlineNavigateBefore size={30} className="p-0 m-0" />
              <span>Back</span>
            </div>
          </Button> */}
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="waves-effect waves-light"
            style={{width: '120px'}}
            disabled={isLoadingCreate}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>Next</span>
              <MdOutlineNavigateNext size={30} className="p-0 m-0" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormPersonal;
