import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import {
  VerticalForm,
  FormInput,
  FormDate,
  FormSelect,
  FormSelectAsync,
} from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { faker } from '@faker-js/faker';

import {
  useRegisterLeadsMutation,
  useSearchLeadsMutation,
  useGetOptionQuery,
  useSearchAutocompleteMutation,
} from "services/api";
import { useEffectOnce } from "usehooks-ts";

const validation = yupResolver(
  yup.object().shape({
    grade_code: yup.string().label('Grade').required(''),
    school_code: yup.string().label('School').required(''),
    school_name: yup.mixed().default('').when('school_code', {
      is: 'Other',
      then: yup.string().label('School Name').required(''),
    }),
    // country_code: yup.string().label('Destination Country').required(''),
    planning_year: yup.mixed().label('Planning year').required(''),
    // course_level_code: yup.string().label('Course level').required(''),
    course_major_code: yup.string().label('Study Area').required(''),
    // course_full_name_code: yup.string().label('Course full name').required(''),
    marketing_source_code: yup.string().label('Marketing source').required(''),
    agreement: yup.boolean().oneOf([true]).label('Agreement').required(''),
  })
);

const yearOptions = () => {
  let sy = new Date().getFullYear()
  let ey = sy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}

const FormInterested = ({
  slug,
  expo,
  layout,
  setLayout,
  setLeads,
  leads,
  isNewLeads,
  setIsNewLeads,
}: {
  slug?: string;
  expo?: any;
  layout: string;
  setLayout: any;
  setLeads: any;
  leads: any;
  isNewLeads: boolean;
  setIsNewLeads: any;
}) => {
  const [leadsCode, setLeadsCode] = useState('JSA23298485');
  const [fullName, setFullName] = useState('');
  const [birth, setBirth] = useState('');
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  
  const [gradeCode, setGradeCode] = useState(leads?.grade_code || '')
  const [schoolCode, setSchoolCode] = useState((leads?.school_code) ? {
    value: leads?.school_code,
    label: leads?.school_name
  } : (
    leads?.school_name ? {label: 'Other',value: 'Other'} : undefined
  ))

  const [searchLeads, { isLoading: isLoadingSearch }] = useSearchLeadsMutation();

  const [registerLeads, { isUninitialized: isUninitializedRegister, isLoading: isLoadingRegister, isSuccess: isSuccessRegister, isError: isErrorRegister}] = useRegisterLeadsMutation();
  
  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchLeads({
        slug,
        search: inputValue,
        response_type: 'select-option',
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const { data: gradeList, isLoading: isLoadingGrade } = useGetOptionQuery({type: 'GRD'});
  // const { data: schoolList, isLoading: isLoadingSchool } = useGetOptionQuery({type: 'SCH'});
  const { data: institutionList, isLoading: isLoadingInstitution } = useGetOptionQuery({type: 'INS'});
  const { data: countryList, isLoading: isLoadingcountry } = useGetOptionQuery({type: 'CTR'});
  const { data: courseLevelList, isLoading: isLoadingCourseLevel } = useGetOptionQuery({type: 'CRL'});
  const { data: courseMajorList, isLoading: isLoadingCourseMajor } = useGetOptionQuery({type: 'CMJ'});
  const { data: courseFullNameList, isLoading: isLoadingCourseFullName } = useGetOptionQuery({type: 'CFN'});
  const { data: marketingSourceList, isLoading: isLoadingMarketingSource } = useGetOptionQuery({type: 'MRS'});

  const [searchAutocomplete, { isLoading: isLoadingSearchAutocomplete }] = useSearchAutocompleteMutation();

  const loadSchoolOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchAutocomplete({
        type: 'SCH',
        // filter: {
        //   grade_code: getValues()['grade_code'],
        // },
        search: inputValue,
        limit: 100,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res?.result)
          if(res?.result?.length){
            callback(res?.result);
          } else {
            callback([{
              label: "Other",
              value: "Other"
            }]);
          }
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
    trigger,
  } = methods;

  const onSubmit = async (values: any) => {
    await registerLeads({
      slug,
      ...leads,
      ...values,
      school_code: schoolCode?.label != 'Other' ? schoolCode?.value : null,
    })
      .unwrap()
      .then((res: any) => {
        if(res.result){
          setLayout('success')
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
  };

  const onClickBack = () => {
    if(isNewLeads){
      setLayout('personal')
    } else {
      setLayout('confirmation')
    }
  }

  useEffectOnce(() => {
    if(leads?.grade_code){
      // console.log('grade_code exists')
      setValue('grade_code',leads?.grade_code);
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }

    if(leads?.school_code){
      // console.log('school_code exists')
      setValue('school_code',leads?.school_code);
    } else if(leads?.school_name){
      setSchoolCode({
        label: "Other",
        value: "Other"
      })
      setValue('school_code','Other');
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }

    if(leads?.country_code){
      // console.log('country_code exists')
      setValue('country_code',leads?.country_code);
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }
    
    if(leads?.planning_year){
      // console.log('planning_year exists')
      setValue('planning_year',leads?.planning_year);
    } else {
      // setValue('planning_year','202'+faker.number.int({ min: 4, max: 9 }));
    }
    
    if(leads?.course_level_code){
      // console.log('course_level_code exists')
      setValue('course_level_code',leads?.course_level_code);
    } else {
      // setValue('course_level_code','CLE240'+faker.number.int({ min: 1, max: 9 }));
    }
    
    if(leads?.course_major_code){
      // console.log('course_major_code exists')
      setValue('course_major_code',leads?.course_major_code);
    } else {
      // setValue('course_major_code','CMJ240'+faker.number.int({ min: 1, max: 9 }));
    }
    
    if(leads?.course_full_name_code){
      // console.log('course_full_name_code exists')
      setValue('course_full_name_code',leads?.course_full_name_code);
    } else {
      // setValue('course_full_name_code','CFM240'+faker.number.int({ min: 1, max: 9 }));
    }
    
    if(leads?.marketing_source_code){
      // console.log('marketing_source_code exists')
      setValue('marketing_source_code',leads?.marketing_source_code);
    } else {
      // setValue('marketing_source_code','MKS2400'+faker.number.int({ min: 1, max: 9 }));
    }
    
  })

  // useEffect(() => {
  //   console.log(errors)
  // }, [errors])

  if(
    isLoadingGrade ||
    // isLoadingSchool ||
    isLoadingInstitution ||
    isLoadingCourseLevel ||
    isLoadingCourseMajor ||
    isLoadingCourseFullName ||
    isLoadingMarketingSource
  ){
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
        <ScaleLoader height={20} width={1} color="#b7b1dd" />
      </div>
    )
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) => onSubmit(values))}
        noValidate
      >
        <h4 className="">Current Education</h4>
        {/* <p className="sub-header">
          Most common form control, text-based input fields. Includes support
          for all HTML5 types: 
        </p> */}
        <div className="row">
          <div className="col-12">
            <FormSelect
              label="Grade"
              name="grade_code"
              placeholder="e.g. Year 10 / O Level"
              containerClass={"mb-3"}
              // defaultValue={getValues()['grade_code'] || data?.grade_code || undefined}
              defaultValue={gradeCode || undefined}
              options={(gradeList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(opt: any) => {
                // setSchoolCode(undefined) // {}
                // setValue('school_code', undefined)
                setGradeCode(opt.value)
                setValue('grade_code', opt.value)
              }}
              isClearable
            />
            {/* <FormSelect
              label="Grade"
              name="grade_code"
              placeholder="e.g. SMA"
              containerClass={"mb-3"}
              defaultValue={getValues()['grade_code'] || leads?.grade_code || undefined}
              options={(gradeList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(opt: any) => {
                console.log(opt)
                // setCalcMarketingBranding(opt.value);
              }}
            /> */}
          </div>
          <div className="col-12">
            {/* <FormSelect
              label="School"
              type="text"
              name="school_code"
              placeholder="e.g. SMA N 1 Jakarta"
              containerClass={"mb-3"}
              defaultValue={getValues()['school_code'] || leads?.school_code || undefined}
              options={(schoolList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              // onChange={(opt: any) => {
              //   setCalcMarketingBranding(opt.value);
              // }}
            /> */}
            
            {/* <FormInput
              label="School"
              type="text"
              name="school_name"
              placeholder="e.g. SMA N 11 Jakarta"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              // defaultValue={leads?.school_name || undefined}
            /> */}
            
            <FormSelectAsync
              className="w-100"
              label="School"
              name="school_code"
              placeholder="e.g. SMA N 11 Jakarta"
              containerClass={"mb-3"}
              defaultValue={schoolCode}
              // defaultValue={(getValues()['school_code'] || data?.school_code) ? {
              //   value: getValues()['school_code'] || data?.school_code,
              //   label: getValues()['school_name'] || data?.school_name
              // } : undefined}
              // defaultOptions={data?.school_code ? [{
              //   value: data?.school_code,
              //   label: data?.kecamatan_name
              // }] : undefined}
              loadOptions={loadSchoolOptions}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              // setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              onChange={(option: any, actionname: any) => {
                setValue('school_code', option.value)
                if(option.label == 'Other'){
                  setValue('school_name', '')
                } else {
                  setValue('school_name', option.label)
                }
                setSchoolCode(option)
                if(actionname.action == 'clear'){
                  setSchoolCode(undefined)
                  setValue('school_code', '')
                  setValue('school_name', '')
                }
              }}
              isClearable
            />
          </div>
            
          {
            (schoolCode?.label == 'Other') &&
            <FormInput
              className=""
              label="Enter school name"
              type="text"
              name="school_name"
              placeholder="e.g. One World International School"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.school_name || undefined}
              // onChange={(e: any) => {
              //   if(e.target.value == ''){
              //     setValue('school_code', '')
              //     alert('asd')
              //   }
              // }}
              // disabled
            />
          }
        </div>

        <h4 className="">Interested In</h4>
        {/* <p className="sub-header">
          Most common form control, text-based input fields. Includes support
          for all HTML5 types: 
        </p> */}
        <div className="row">
          {/* <div className="col-12 col-sm-8">
            <FormSelect
              label="Destination Country"
              name="country_code"
              placeholder="e.g. Singapore"
              containerClass={"mb-3"}
              defaultValue={getValues()['country_code'] || leads?.country_code || undefined}
              options={(countryList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              onChange={(opt: any) => {
                console.log(opt)
                // setCalcMarketingBranding(opt.value);
              }}
            />
          </div> */}
          <div className="col-12 col-sm-4">
            <FormSelect
              label="Year of Study"
              type="text"
              name="planning_year"
              placeholder="e.g. 2024"
              containerClass={"mb-3"}
              defaultValue={getValues()['planning_year'] || leads?.planning_year || undefined}
              options={yearOptions()}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              // onChange={(opt: any) => {
              //   setCalcMarketingBranding(opt.value);
              // }}
            />
          </div>
        {/* </div> */}
        {/* <div className="row"> */}
          {/* <div className="col-5">
            <FormSelect
              label="Course Level"
              name="course_level_code"
              placeholder="e.g. Foundation"
              containerClass={"mb-3"}
              defaultValue={getValues()['course_level_code'] || leads?.course_level_code || undefined}
              options={(courseLevelList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              // onChange={(opt: any) => {
              //   setCalcMarketingBranding(opt.value);
              // }}
            />
          </div> */}
          <div className="col-12 col-sm-8">
            <FormSelect
              label="Study Area"
              name="course_major_code"
              placeholder="e.g. Science"
              containerClass={"mb-3"}
              defaultValue={getValues()['course_major_code'] || leads?.course_major_code || undefined}
              options={(courseMajorList?.result || [])}
              // disabled={isLoading}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              // onChange={(opt: any) => {
              //   setCalcMarketingBranding(opt.value);
              // }}
            />
          </div>
        </div>
        {/* <FormSelect
          label="Course Full Name"
          name="course_full_name_code"
          placeholder="e.g. Sport Science"
          containerClass={"mb-3"}
          defaultValue={getValues()['course_full_name_code'] || leads?.course_full_name_code || undefined}
          options={(courseFullNameList?.result || [])}
          // disabled={isLoading}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          // onChange={(opt: any) => {
          //   setCalcMarketingBranding(opt.value);
          // }}
        /> */}

        <h4 className="">Reference</h4>
        {/* <p className="sub-header">
          Most common form control, text-based input fields. Includes support
          for all HTML5 types: 
        </p> */}
        <FormSelect
          label="How did you hear about this event?"
          name="marketing_source_code"
          placeholder=""
          containerClass={"mb-3"}
          defaultValue={getValues()['marketing_source_code'] || leads?.marketing_source_code || undefined}
          options={(marketingSourceList?.result || [])}
          // disabled={isLoading}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          // onChange={(opt: any) => {
          //   setCalcMarketingBranding(opt.value);
          // }}
        />
        <FormInput
          label="I agree to let JACK Study Abroad and/or participating institutions in this expo to share further information through emails and phone calls."
          type="checkbox"
          name="agreement"
          containerClass={"mb-3 fw-light"}
          className="fw-light"
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          defaultValue={leads?.school_name || undefined}
        />

        <hr/>
        <div className="text-start d-flex justify-content-between gap-2">
          <Button
            size="lg"
            variant="light"
            type="button"
            className="waves-effect waves-light"
            onClick={() => onClickBack()}
            style={{width: '120px'}}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <MdOutlineNavigateBefore size={30} className="p-0 m-0" />
              <span>Back</span>
            </div>
          </Button>
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="waves-effect waves-light"
            style={{width: '120px'}}
            // onClick={() => onClickSubmit()}
            disabled={isLoadingRegister}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>Register</span>
              <MdOutlineNavigateNext size={30} className="p-0 m-0" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormInterested;
