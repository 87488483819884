import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import {
  VerticalForm,
  FormInput,
  FormDate,
  FormSelect,
  FormSelectAsync,
} from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";

import {
  useConfirmLeadsMutation,
} from "services/api";

const validation = yupResolver(
  yup.object().shape({
    // slug: yup.string().label('JACK Study Abroad ID').required(''),
    // leads_code: yup.string().label('JACK Study Abroad ID').required(''),
  })
);

const FormPersonal = ({
  slug,
  expo,
  layout,
  setLayout,
  setLeads,
  leads,
}: {
  slug?: string;
  expo?: any;
  layout: string;
  setLayout: any;
  setLeads: any;
  leads: any;
}) => {
  const [leadsCode, setLeadsCode] = useState('JSA23298485');
  const [fullName, setFullName] = useState('');
  const [birth, setBirth] = useState('');
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const [confirmLeads, { isUninitialized: isUninitializedConfirm, isLoading: isLoadingConfirm, isSuccess: isSuccessConfirm, isError: isErrorConfirm}] = useConfirmLeadsMutation();
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (values: any) => {
    await confirmLeads({
      slug,
      leads_code: leads.leads_code,
    })
      .unwrap()
      .then((res: any) => {
        if(res.result){
          setLeads({
            ...res.result,
            ...leads
          })
          setLayout('interested')
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
  };

  const onClickBack = () => {
    setLeads({})
    setLayout('check')
  }

  return (
    <div>
      <Alert variant="success">
        We found your JACK Study Abroad ID. Please confirm your personal info:
      </Alert>
      {/* <h4 className="">Confirm your personal info</h4> */}
      {/* <p className="sub-header">
        Most common form control, text-based input fields. Includes support
        for all HTML5 types: 
      </p> */}
      <form
        onSubmit={handleSubmit((values) => onSubmit(values))}
        noValidate
      >
        <div className="row">
          <div className="col-12">
            <FormInput
              className="form-control-lg bg-light-subtle"
              label="JACK Study Abroad ID"
              type="text"
              name="leads_code"
              placeholder="-"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.leads_code || undefined}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormInput
              className="form-control-lg bg-light-subtle"
              label="Full Name"
              type="text"
              name="full_name"
              placeholder="-"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.full_name || undefined}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormInput
              className="form-control-lg bg-light-subtle"
              label="Email"
              type="text"
              name="email"
              placeholder="-"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.email || undefined}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormInput
              className="form-control-lg bg-light-subtle"
              label="Phone"
              type="text"
              name="phone"
              placeholder="-"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.phone || undefined}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormInput
              className="form-control-lg bg-light-subtle"
              label="Date of Birth"
              type="text"
              name="dob"
              placeholder="-"
              containerClass={"mb-3"}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValue={leads?.dob || undefined}
              disabled
            />
          </div>
        </div>
        <hr/>
        <div className="text-start d-flex justify-content-between gap-2">
          <Button
            size="lg"
            variant="light"
            type="button"
            className="waves-effect waves-light"
            onClick={() => onClickBack()}
            style={{width: '120px'}}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <MdOutlineNavigateBefore size={30} className="p-0 m-0" />
              <span>Back</span>
            </div>
          </Button>
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="waves-effect waves-light"
            style={{width: '120px'}}
            disabled={isLoadingConfirm}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>Confirm</span>
              <MdOutlineNavigateNext size={30} className="p-0 m-0" />
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormPersonal;
